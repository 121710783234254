import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "react-bootstrap/Image"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Header from "../components/header"
import Carousel from "react-bootstrap/Carousel"
import { useStaticQuery, graphql } from "gatsby"

/**
 * TODO: Two text paragraphs. - move to contentful
 *
 * In the middle was review quotes that cycled. 4 or 5 with images.
 */

const Biography = () => {
  const {
    allContentfulTestimonial: { nodes },
  } = useStaticQuery(
    graphql`
      query MyQuery {
        allContentfulTestimonial {
          nodes {
            id
            testimonialText {
              testimonialText
            }
            attribution
          }
        }
      }
    `
  )

  return (
    <Layout>
      <SEO title="Biography" />
      <Header title="Biography" />
      <Container
        fluid="md"
        style={{
          padding: `0 2rem`,
        }}
      >
        <Row>
          <Col>
            <Image
              src="/images/markiyan_playing_violin_cropped.jpg"
              style={{
                float: `right`,
                width: `50%`,
                margin: `0 0 1rem 1rem`,
              }}
            />
            <p>
              Ukrainian-Australian violinist Markiyan Melnychenko CF has been
              described as “an artist of patrician sensibility, spectacular
              technique”, and “possessing an extraordinary combination of
              bravura, brains and elegance”. He has served as Lecturer in Violin
              at the Melbourne Conservatorium of Music and Principal 1st Violin
              with the Melbourne Chamber Orchestra.
            </p>
            <p>
              Awarded the Dr Iain C. Medgett Churchill Fellowship in 2019, he
              has performed in 17 countries including solo performances in New
              York (Alice Tully Hall), Washington DC (Kennedy Centre), and has
              made a number of solo appearances with orchestras including
              performances with the Lviv Philharmonic Orchestra, Kobe City
              Chamber Orchestra, Collegium Musicum, Melbourne Chamber Orchestra,
              Corpus Medicorum and Orchestra Victoria. He has been broadcast on
              ABC Classic FM, 3MBS FM and Radio NZ, and has commercially
              released recordings on the Tall Poppies (2019) and Naxos (2020)
              labels.
            </p>
            <p>
              In 2013 he was selected to perform in a quartet at the US Capitol
              for the Inaugural Luncheon at Barack Obama's Presidential
              Inauguration. He is a multiple 1st prize winner in national and
              international competitions including the Melbourne Recital
              Centre's National “Great Romantics” competition and the Oleh Krysa
              International Violin Competition in Ukraine. He was also awarded
              the 'Australian Development Prize' from the Michael Hill
              International Violin Competition in 2013.
            </p>
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", margin: `4rem 0` }}>
          <Carousel controls={false}>
            {(nodes || []).map(
              ({ id, testimonialText: { testimonialText }, attribution }) => (
                <Carousel.Item
                  key={id}
                  style={{ textAlign: "center", maxWidth: `90vw` }}
                >
                  <h1
                    style={{
                      fontSize: `5rem`,

                      margin: `0 0 -2rem 0`,
                    }}
                  >
                    &ldquo;
                  </h1>
                  <h3
                    style={{
                      marginBottom: `2rem`,
                    }}
                  >
                    {testimonialText}
                  </h3>
                  <h3 style={{ color: `#aaaaaa`, fontSize: `1.1427rem` }}>
                    -{attribution}
                  </h3>
                </Carousel.Item>
              )
            )}
          </Carousel>
        </Row>
        <Row>
          <Col>
            <Image
              src="/images/markiyan_holding_violin_white_backer_cropped.jpg"
              style={{
                float: `left`,
                width: `50%`,
                margin: `0 1rem 0 0`,
              }}
            />

            <p>
              Markiyan obtained his Bachelor and Masters of Music at the Eastman
              School of Music under the tutelage of Professor of Violin – Oleh
              Krysa. He was additionally awarded Eastman's Performer's
              certificate and the highly prestigious Artist Certificate, being
              the first violinist to receive this award in over 50 years.
              Additional studies have been at the Australian National Academy of
              Music with Alice Waten and William Hennessy, and also with Cyrus
              Forough.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Biography
